import isEmpty from 'lodash.isempty';

// components
import { Title } from '../Title/Title';
import { CmsLink } from 'components/Link/CmsLink';
import { InnerHtml } from 'components/InnerHtml/inner-html';
import HoverImage from 'components/Image/HoverImage';

// utils
import { TitleFormats } from '../Title/title.types';
import { useSectionId } from 'utils/hooks/use-section-id';
import { useGdds } from 'utils/hooks/use-gdds';
import { classNameBuilder } from 'utils/classNameBuilder';

// types
import type { TextImageProps } from './types';

function getTitleFormat(isGdds: boolean, isChild: boolean, isTeaserColumn = false) {
  let format = isTeaserColumn ? TitleFormats.h3 : TitleFormats.h2;
  if (isGdds) {
    format = TitleFormats.h3;
  } else if (isChild) {
    format = TitleFormats.h4;
  }
  return format;
}

export function TextImage({
  images,
  style,
  content,
  headline,
  subline,
  button,
  teaserTitle,
  teaserContent,
  border,
  title,
  subtitle,
  anchor,
  contentIndex,
  titleInAnchor,
  children,
  className,
  isSectionReference,
  isChild = false,
}: Readonly<TextImageProps>) {
  const sectionId = useSectionId(title, anchor, titleInAnchor, contentIndex);
  const borderClass = border === 'disable-border' ? `c-text-image--${border}` : '';
  const hasTeaserColum = !isEmpty(teaserContent);
  const isGdds = useGdds();

  return (
    <div className="grid-container" id={sectionId}>
      <div className={classNameBuilder('c-text-image', borderClass, className)}>
        <Title
          title={title}
          subtitle={subtitle}
          Format={getTitleFormat(isGdds, isChild)}
          titlePreviewId="#st_title"
          subtitlePreviewId="#st_subtitle"
          isSectionReference={isSectionReference}
          formatClassName={isGdds ? undefined : 'h3'}
        />
        <div className="c-text-image--wrapper">
          <div
            className={classNameBuilder(
              `c-text-image--${style}`,
              hasTeaserColum && 'c-text-image--teaser-column',
            )}
          >
            <HoverImage images={images} className="c-text-image__image" />
            <div className="c-text-image__text">
              {(headline || subline) && !hasTeaserColum && (
                <Title
                  title={headline}
                  subtitle={subline}
                  Format={TitleFormats.h4}
                  titlePreviewId="#st_titleLandingpage"
                  subtitlePreviewId="#st_subtitleLandingpage"
                  isSectionReference={isSectionReference}
                />
              )}
              {!isEmpty(children) && children}
              {!isEmpty(content) && (
                <InnerHtml
                  as="div"
                  content={content}
                  previewId="#st_content"
                  isSectionReference={isSectionReference}
                />
              )}
              {!isEmpty(button) && button && !isEmpty(button.text) && (
                <CmsLink
                  className="button button--white button--background-primary"
                  link={button}
                />
              )}
            </div>
            {hasTeaserColum && (
              <div className="c-text-image__teaser">
                <Title
                  title={teaserTitle}
                  Format={getTitleFormat(isGdds, isChild, true)}
                  titlePreviewId="#st_teaserTitle"
                  isSectionReference={isSectionReference}
                  formatClassName={isGdds ? undefined : 'h3'}
                />
                <InnerHtml
                  as="div"
                  content={teaserContent}
                  previewId="#st_teaserContent"
                  isSectionReference={isSectionReference}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
