import { useState } from 'react';
import key from 'weak-key';
import { CSSTransition } from 'react-transition-group';
import isEqual from 'lodash.isequal';

// styles
import styles from './hoverImage.scss';

// components
import { Image } from './Image';
import { BUTTON_TYPES, Button } from '../Button/Button';

// utils
import { useIsMobile, useIsTablet } from 'components/App/SizeProvider';
import { useIsPreview } from 'utils/hooks/useIsPreview';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { isEmpty } from 'utils/is-empty';

export type Image = {
  pictureObject: ImageObject;
  pictureHoverObject: ImageObject;
  pictureAlt: string;
  pictureHoverAlt: string;
  description: string;
  imgResolutionsPicture: string;
  pictureHover: string;
};

interface HoverImageProps {
  images: Image[];
  className?: string;
}

function HoverImage({ images, className }: Readonly<HoverImageProps>) {
  const isPreview = useIsPreview();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const translate = useTranslationFunction();
  const [activeHoverImages, setActiveHoverImages] = useState<ImageObject[]>([]);

  if (isEmpty(images)) return null;

  /**
   * Handle active hover images
   * @param {Object} currentImage
   */
  const handleHoverImages = (currentImage: ImageObject) => {
    const hasImage = hasCurrentHoverImage(currentImage);
    if (hasImage) {
      const newActiveImages = activeHoverImages.filter((image) => !isEqual(image, currentImage));
      setActiveHoverImages([...newActiveImages]);
    } else {
      setActiveHoverImages([...activeHoverImages, currentImage]);
    }
  };

  /**
   * check if current hover image is active
   * @param {Object} currentImage
   */
  const hasCurrentHoverImage = (currentImage) =>
    activeHoverImages.some((image) => isEqual(image, currentImage));

  return (
    <div className={className}>
      {images.map((image) => {
        const activeHover = hasCurrentHoverImage(image.pictureHoverObject);

        return (
          <div className={`${styles.singleImage} single-image-wrapper`} key={key(image)}>
            <Image
              data={image.pictureObject}
              alt={image.pictureAlt}
              title={image.description || image.pictureAlt || ''}
              format={image.imgResolutionsPicture}
            />
            <CSSTransition in={activeHover} classNames="fade" timeout={200}>
              <div className={`${styles.hoverImage}`}>
                <Image
                  data={image.pictureHoverObject}
                  alt={image.pictureHoverAlt}
                  title={image.description || image.pictureHoverAlt || ''}
                  format={image.imgResolutionsPicture}
                />
              </div>
            </CSSTransition>
            {!isEmpty(image.pictureHover) && (
              <div className={`${styles.buttonWrapper} ${activeHover ? styles.active : ''}`}>
                <Button
                  type={BUTTON_TYPES.PLAIN}
                  symbol="hover_icon-hover-image"
                  // if we have mouseenter on mobile we wont get the right result
                  // also in preview we need the click event to crop the images
                  {...(!isMobile && !isTablet && !isPreview
                    ? { onMouseEnter: () => handleHoverImages(image.pictureHoverObject) }
                    : {})}
                  {...(!isMobile && !isTablet && !isPreview
                    ? { onMouseLeave: () => handleHoverImages(image.pictureHoverObject) }
                    : {})}
                  {...(isMobile || isTablet || isPreview
                    ? {
                        onClick: () => handleHoverImages(image.pictureHoverObject),
                      }
                    : {})}
                  aria-label={translate('web20_reveal_image')}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default HoverImage;
